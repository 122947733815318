import * as React from "react";
import { useLocation } from "@gatsbyjs/reach-router";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo";
import Cookies from "./cookies";

type Props = {
  title?: string;
  description?: string;
  image?: string;
  logo?: string;
  article?: boolean;
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        defaultLogo: logo
        twitterUsername
      }
    }
  }
`;

const Seo: React.FC<Props> = ({ title, description, image, logo, article = false }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage, defaultLogo, twitterUsername } =
    site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || t(defaultDescription),
    image: `${siteUrl}${image || defaultImage}`,
    logo: `${siteUrl}${logo || defaultLogo}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <>
      <GatsbySeo
        title={seo.title}
        titleTemplate={titleTemplate}
        defer={false}
        description={seo.description}
        metaTags={[
          {
            property: "image",
            name: "image",
            content: seo.image,
          },
          {
            name: "referrer",
            content: "no-referrer-when-downgrade",
          },
        ]}
        openGraph={{
          type: article ? "article" : "website",
          title: seo.title,
          description: seo.description,
          images: [
            {
              url: seo.image,
            },
          ],
          url: seo.url,
        }}
        twitter={{
          cardType: "summary_large_image",
          site: twitterUsername,
        }}
      />
      <LogoJsonLd logo={seo.logo} url={siteUrl} />
      <Cookies />
    </>
  );
};

export default Seo;
