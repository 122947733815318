import * as React from "react";
import CookieConsent, { Cookies as CA } from "react-cookie-consent";
import { MdBlock, MdDoneAll } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Cookies: React.FC = () => {
  const { t } = useTranslation("translation");
  useEffect(() => {
    if (CA.get("gatsby-gdpr-google") === "true") {
      if (typeof window.clarity === "function") {
        window.clarity("consent");
      }
    }
  }, []);
  return (
    <CookieConsent
      overlay
      disableStyles
      overlayClasses="absolute bottom-4 left-4 right-4 rounded-xl z-9999 bg-gray-400 py-4 px-6"
      containerClasses="flex flex-wrap text-white justify-between gap-4 items-center"
      buttonWrapperClasses="flex flex-wrap justify-evenly items-center gap-4"
      buttonClasses="flex justify-start items-center text-white cursor-pointer px-2 py-2 rounded-2xl
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-yellow px-4"
      declineButtonClasses="flex justify-start items-center text-white cursor-pointer px-2 py-2 rounded-2xl
                        transition duration-500 ease-in-out transform hover:scale-110 space-x-2 bg-red px-4"
      location="bottom"
      buttonText={
        <>
          <MdDoneAll />
          <span>{t("cookieOk")}</span>
        </>
      }
      declineButtonText={
        <>
          <MdBlock />
          <span>{t("cookieDecline")}</span>
        </>
      }
      enableDeclineButton
      cookieName="gatsby-gdpr-google"
      onAccept={() => {
        if (typeof window.clarity === "function") {
          window.clarity("consent");
        }
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t("cookieText", {
            ga: `<a
            href="https://marketingplatform.google.com/about/analytics/terms/us/" class="text-yellow underline">Google
            Analytics</a>`,
            gtag: `<a class="text-yellow underline"
                                 href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/">Google
            Tag Manager</a>`,
            clarity: `<a class="text-yellow underline"
                                 href="https://clarity.microsoft.com/terms">Microsoft Clarity</a>`,
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </CookieConsent>
  );
};

export default Cookies;
